import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme } from '@mui/material';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import CookiesNotification from 'src/components/CookiesNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/hooks/useSettings';
import { theme } from './theme';
import routes, { renderRoutes } from 'src/routes';
import { getSubDomainChannelView, isIframe } from 'src/views/utils/Utils';
import { IntlProvider } from 'react-intl';


declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme { }
}

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
    const { settings } = useSettings();
    const [messages, setMessages] = useState<any>();
    const [loading, isLoading] = useState<boolean>(true);

    const subdomainchannel = getSubDomainChannelView();
    function loadLocaleData(locale: string) {
        switch (locale) {
            case 'en':
                return import('./compiled-lang/en.json')
            case 'es':
                return import('./compiled-lang/es.json')
            default:
                return import('./compiled-lang/en.json')
        }
    }

    async function getMessages() {
        if (settings.language !== undefined) {
            setMessages(await loadLocaleData(settings.language.toLowerCase()))
        } else {
            setMessages(await loadLocaleData(navigator.language.split(/[-_]/)[0].toLocaleLowerCase()))
        }
        isLoading(false);
    }
    var flatten = require('flat');

    useEffect(() => {
        getMessages();
    }, [settings.language]);

    if (loading) return null
    else {
        return (
            <StyledEngineProvider injectFirst>
                <IntlProvider
                    locale={settings.language}
                    messages={flatten(messages)}
                >
                    <ThemeProvider theme={theme}>
                        <StylesProvider jss={jss}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <SnackbarProvider
                                    dense
                                    maxSnack={3}
                                >
                                    <Router history={history}>
                                        <AuthProvider>
                                            <GlobalStyles />
                                            <ScrollReset />
                                            <GoogleAnalytics />
                                            {!isIframe() && <CookiesNotification />}
                                            {(subdomainchannel == null) && renderRoutes(routes)}
                                        </AuthProvider>
                                    </Router>
                                </SnackbarProvider>
                            </MuiPickersUtilsProvider>
                        </StylesProvider>
                    </ThemeProvider>
                </IntlProvider>
            </StyledEngineProvider>
        );
    }
};

export default App;
